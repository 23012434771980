import { MusicServiceFactory } from '@/service/MusicServiceFactory';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const musicService = MusicServiceFactory.getMusicServiceInstance();

export const usePlaylistStore = defineStore('playlists', () => {
    const playlists = ref([]);
    const playlistsNextHref = ref('');

    async function init() {
        const playlistsData = await musicService.getPlaylists();
        playlists.value = playlistsData.items;
        playlistsNextHref.value = playlistsData.next;
    }

    async function getNextPlaylists() {
        if (playlistsNextHref.value === null || playlistsNextHref.value === '') return;
        const playlistsData = await musicService.getPlaylists(playlistsNextHref.value);
        playlists.value.push(...playlistsData.items);
        playlistsNextHref.value = playlistsData.next;
    }

    async function addPlaylist(playlistName) {
        const playlistData = await musicService.addPlaylist(playlistName);
        playlists.value.unshift(playlistData);
        return playlistData;
    }

    async function addPlaylistTracks(playlist, tracks) {
        await musicService.addPlaylistTracks(playlist, tracks);
    }

    async function deletePlaylist(playlistId) {
        await musicService.deletePlaylist(playlistId);
        playlists.value = playlists.value.filter((playlist) => playlist.id !== playlistId);
    }

    return { playlists, playlistsNextHref, init, getNextPlaylists, addPlaylist, addPlaylistTracks, deletePlaylist };
});
