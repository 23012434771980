<script setup>
import TagsMenu from '@/components/TrackTagsMenu.vue';
import { useTrackStore } from '@/stores/tracks';
import { useTrackTagStore } from '@/stores/trackTags';
import { useBrowser } from '@/util/browser';
import { onBeforeMount, onUnmounted, ref, watch } from 'vue';

const trackStore = useTrackStore();
const trackTagStore = useTrackTagStore();
const browser = useBrowser();

onBeforeMount(() => {
    trackStore.currentlyPlayingTrackData.item;
    const trackStoreTrack = trackStore.tracks.find((track) => track.id === trackStore.currentlyPlayingTrackData.item.id);
    if (trackStoreTrack) {
        track.value = trackStoreTrack;
    } else {
        track.value = trackStore.currentlyPlayingTrackData.item;
        track.value.tags = trackTagStore.trackTags.find((trackTag) => trackTag.provider_id === track.value.id)?.tags || [];
        trackStore.tracks.push(track.value);
    }

    helpTextIntervalId.value = setInterval(() => {
        showHelpText.value = true;
        setTimeout(() => {
            showHelpText.value = false;
        }, 3000);
    }, 10000);
});

onUnmounted(() => {
    clearInterval(helpTextIntervalId.value);
});

watch(
    () => trackStore.currentlyPlayingTrackData,
    () => {
        track.value = trackStore.currentlyPlayingTrackData.item;
        track.value.tags = trackTagStore.trackTags.find((trackTag) => trackTag.provider_id === track.value.id)?.tags || [];
    }
);

const track = ref({});
const menuRefs = ref({});
const showHelpText = ref(false);
const helpTextIntervalId = ref('');

const setTagsMenuRef = (trackId) => (el) => {
    if (el) {
        menuRefs.value[trackId] = el;
    }
};

function openTagsMenu(trackId, event) {
    const menuRef = menuRefs.value[trackId];
    if (menuRef) {
        menuRef.toggle(event);
    }
}
</script>

<template>
    <div class="flex flex-row justify-between w-full px-4" style="background-image: linear-gradient(to bottom right, var(--p-primary-950), var(--p-slate-950))">
        <div class="flex items-center">
            <img class="rounded" :src="track.album?.images[2].url" :alt="track.name" style="width: 30px; height: 30px; max-width: 30px; max-height: 30px; cursor: pointer" />
        </div>
        <div id="songNameAndArtist" class="flex flex-col items-center text-nowrap">
            <Transition name="fade" mode="out-in">
                <div v-if="showHelpText">
                    <p class="text-center" style="margin: 0">Refresh to update</p>
                    <p class="text-center" style="margin: 0">your Now Playing track</p>
                </div>
                <div v-else :style="browser.isMobile() ? 'max-width: 20rem' : null">
                    <p class="text-center text-ellipsis overflow-hidden" style="margin: 0">{{ track.name }}</p>
                    <p class="text-center text-ellipsis overflow-hidden text-muted-color" style="margin: 0">{{ track.artists?.map((artist) => artist.name).join(', ') }}</p>
                </div>
            </Transition>
        </div>
        <div class="flex items-center justify-end">
            <Button type="button" @click="openTagsMenu(track.id, $event)" aria-haspopup="true" :aria-controls="track.id">
                <i class="pi pi-angle-down"></i>
            </Button>
            <TagsMenu :menu-ref-func="setTagsMenuRef(track.id)" :track="track" />
        </div>
    </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
