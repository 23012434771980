<script setup>
import { usePlaylistStore } from '@/stores/playlists';
import { useTagStore } from '@/stores/tags';
import { useTagSorter } from '@/util/tagSorter';
import { PrimeIcons } from '@primevue/core/api';
import { vInfiniteScroll } from '@vueuse/components';
import { onBeforeMount, ref, watch } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const tagStore = useTagStore();
const playlistStore = usePlaylistStore();
const tagSorter = useTagSorter();

onBeforeMount(() => {
    updateMenuTags();
    updateMenuPlaylists();
});

watch(() => tagStore.tags, updateMenuTags, { deep: true });
watch(() => playlistStore.playlists, updateMenuPlaylists, { deep: true });

const menuItems = ref([
    {
        label: 'Home',
        items: [
            {
                label: 'Dashboard',
                icon: PrimeIcons.HOME,
                to: '/dashboard'
            }
        ]
    },
    {
        label: 'Your Tags',
        cornerIcon: {
            icon: PrimeIcons.COG,
            to: '/dashboard/tags'
        },
        items: []
    },
    {
        label: 'Your Playlists',
        items: []
    }
]);

function updateMenuTags() {
    menuItems.value[1].items = tagSorter
        .sortTags(tagStore.tags)
        .filter((t) => !t.parent_id)
        .map((tag) => ({
            label: tag.name,
            icon: !tag.children || tag.children.length === 0 ? PrimeIcons.TAG : PrimeIcons.FOLDER,
            to: `/dashboard/tags/${tag.id}`,
            items:
                !tag.children || tag.children.length === 0
                    ? null
                    : [
                          {
                              label: tag.name,
                              icon: PrimeIcons.TAG,
                              to: `/dashboard/tags/${tag.id}`
                          },
                          ...tag.children.map((child) => ({
                              label: child.name,
                              icon: !child.children || child.children.length === 0 ? PrimeIcons.TAG : PrimeIcons.FOLDER,
                              to: `/dashboard/tags/${child.id}`,
                              items:
                                  !child.children || child.children.length === 0
                                      ? null
                                      : [
                                            {
                                                label: child.name,
                                                icon: PrimeIcons.TAG,
                                                to: `/dashboard/tags/${child.id}`
                                            },
                                            ...child.children.map((grandchild) => ({
                                                label: grandchild.name,
                                                icon: PrimeIcons.TAG,
                                                to: `/dashboard/tags/${grandchild.id}`
                                            }))
                                        ]
                          }))
                      ]
        }));
}

function updateMenuPlaylists() {
    menuItems.value[2].items = playlistStore.playlists.map((playlist) => ({
        label: playlist.name,
        icon: PrimeIcons.LIST,
        to: `/dashboard/playlists/${playlist.id}`
    }));
    menuItems.value[2].items.unshift({
        label: 'Saved Tracks',
        icon: PrimeIcons.CHECK_CIRCLE,
        to: '/dashboard/playlists/saved-tracks'
    });
}

async function onLoadMore() {
    await playlistStore.getNextPlaylists();
}
</script>

<template>
    <div v-infinite-scroll="[onLoadMore, { interval: 500, canLoadMore: () => playlistStore.playlistsNextHref !== null }]" class="overflow-y-auto max-h-full">
        <ul class="layout-menu">
            <template v-for="(item, i) in menuItems" :key="item">
                <app-menu-item v-if="!item.separator" :item="item" :index="i" ref="el"></app-menu-item>
                <li v-if="item.separator" class="menu-separator"></li>
            </template>
        </ul>
    </div>
</template>

<style lang="scss" scoped></style>
