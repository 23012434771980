export function useTagSorter() {
    // TO HUMAN READERS: This was written with CoPilot AI... it should be working but good luck if you have to fix it :)
    function sortTags(tags) {
        const tagMap = new Map();
        const parentMap = new Map();
        const tagIds = new Set(tags.map((tag) => tag.id));

        // Build maps for quick lookups and group tags by parent_id
        for (const tag of tags) {
            tagMap.set(tag.id, tag);

            const parentId = tag.parent_id === undefined || tag.parent_id === null || !tagIds.has(tag.parent_id) ? 'root' : tag.parent_id;

            if (!parentMap.has(parentId)) {
                parentMap.set(parentId, []);
            }
            parentMap.get(parentId).push(tag);
        }

        // Sort the children arrays by name
        for (const [_, children] of parentMap) {
            children.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'case' }));
        }

        // Use a stack to avoid recursion
        const result = [];
        const stack = [];

        // Start from the 'root' parent
        if (parentMap.has('root')) {
            const rootChildren = parentMap.get('root');
            // Add in reverse order since we'll be using a stack (LIFO)
            for (let i = rootChildren.length - 1; i >= 0; i--) {
                stack.push(rootChildren[i]);
            }
        }

        while (stack.length > 0) {
            const currentTag = stack.pop();
            result.push(currentTag);

            const children = parentMap.get(currentTag.id);
            if (children && children.length > 0) {
                // Add children to stack in reverse order to maintain sort order
                for (let i = children.length - 1; i >= 0; i--) {
                    stack.push(children[i]);
                }
            }
        }

        return result;
    }

    return { sortTags };
}
