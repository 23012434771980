export default class IMusicService {
    constructor() {
        if (new.target === IMusicService) {
            throw new TypeError('Cannot construct IMusicService instances directly');
        }
    }

    isPremium() {
        throw new Error('Method not implemented.');
    }

    async init() {
        throw new Error('Method not implemented.');
    }

    async playTrack(trackId) {
        throw new Error('Method not implemented.');
    }

    async addTrackToQueue(trackId) {
        throw new Error('Method not implemented.');
    }

    async getCurrentUsersProfile() {
        throw new Error('Method not implemented.');
    }

    async getCurrentlyPlayingTrackData() {
        throw new Error('Method not implemented.');
    }

    async getUsersSavedTracks(offset, limit) {
        throw new Error('Method not implemented.');
    }

    async getTracks(trackIds) {
        throw new Error('Method not implemented.');
    }

    async getArtists(artistIds) {
        throw new Error('Method not implemented.');
    }

    async getPlaylists(next) {
        throw new Error('Method not implemented.');
    }

    async getPlaylistTracks(playlist, offset, limit) {
        throw new Error('Method not implemented.');
    }

    async addPlaylist(playlistName) {
        throw new Error('Method not implemented.');
    }

    async addPlaylistTracks(playlist, tracks) {
        throw new Error('Method not implemented.');
    }

    async deletePlaylist(playlistId) {
        throw new Error('Method not implemented.');
    }

    async refreshToken() {
        throw new Error('Method not implemented.');
    }
}
