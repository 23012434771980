import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', () => {
    const FREE_TIER_MAX_TAGS = 3;

    const SUBSCRIPTION_TIERS = {
        FREE: 'Free',
        PREMIUM: 'Premium'
    };

    // The source of truth for this is the Stripe webhook itself which will contain this key.
    // This is used here only for purposes of the hacks below.
    const STRIPE_ENTITLEMENT_LOOKUP_KEY = 'premium';

    const subscription = hasPremium() ? SUBSCRIPTION_TIERS.PREMIUM : SUBSCRIPTION_TIERS.FREE;

    function hasPremium() {
        // NOTE: The "premium" magic string is defined by the Stripe Entitlement's Lookup Key.
        return JSON.parse(sessionStorage.session).user.user_metadata.stripe_entitlement === STRIPE_ENTITLEMENT_LOOKUP_KEY;
    }

    // This function is a hack used to set the user's subscription status to "premium". The idea is for this
    // to be called from the Account page after the user has successfully paid for the subscription. This is
    // a hack because the user's subscription status should be updated by the webhook processor, not the client.
    // But, due to the time delay in receiving the webhook, we need to update the user's subscription status
    // immediately so that the client can display the correct UI. Worst case scenario, the user hasn't actually
    // paid but has hacked the system by going to the Account page with a session_id and triggered this method,
    // which will give them free premium until the next time they log in, at which point the true value from the
    // DB will be fetched and the user will be downgraded.
    function setPremiumOnSession() {
        const session = JSON.parse(sessionStorage.session);
        session.user.user_metadata.stripe_entitlement = STRIPE_ENTITLEMENT_LOOKUP_KEY;
        sessionStorage.setItem('session', JSON.stringify(session));
    }

    return { FREE_TIER_MAX_TAGS, SUBSCRIPTION_TIERS, subscription, hasPremium, setPremiumOnSession };
});
