import { SpotifyService } from './SpotifyService';

export class MusicServiceFactory {
    static #createMusicService(provider) {
        switch (provider) {
            case SpotifyService.SUPABASE_PROVIDER_NAME:
                return SpotifyService.getInstance();
            default:
                throw new Error('Unsupported music provider');
        }
    }

    static getMusicServiceInstance() {
        // const session = JSON.parse(sessionStorage.session);
        // return this.#createMusicService(session?.user.app_metadata.provider);
        return this.#createMusicService('spotify');
    }
}
