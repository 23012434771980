import * as Sentry from '@sentry/vue';
import axios from 'axios';

export function useDiscord() {
    const CHANNELS = {
        FEEDBACK: import.meta.env.VITE_DISCORD_FEEDBACK_WEBHOOK_URL
    };

    async function postMessage(channel, content) {
        try {
            await axios.post(channel, { content });
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    return { CHANNELS, postMessage };
}
