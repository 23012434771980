<script setup>
import { MusicServiceFactory } from '@/service/MusicServiceFactory';
import { supabase } from '@/service/SupabaseService';
import { usePlaylistStore } from '@/stores/playlists';
import { useTagStore } from '@/stores/tags';
import { useTrackStore } from '@/stores/tracks';
import { useTrackTagStore } from '@/stores/trackTags';
import * as Sentry from '@sentry/browser';
import { onMounted } from 'vue';
import router from './router';

onMounted(() => {
    supabase.auth.getSession().then(({ data }) => {
        sessionStorage.setItem('session', JSON.stringify(data.session));
    });

    supabase.auth.onAuthStateChange((event, session) => {
        sessionStorage.setItem('session', JSON.stringify(session));
        if (session?.provider_token) {
            sessionStorage.setItem('provider_token', session?.provider_token);
        }
        if (session?.provider_refresh_token) {
            sessionStorage.setItem('provider_refresh_token', session?.provider_refresh_token);
        }

        if (event === 'INITIAL_SESSION') {
            const user = JSON.parse(sessionStorage.session)?.user;
            Sentry.setUser({ id: user?.id });
            if (session?.provider_token) {
                const musicService = MusicServiceFactory.getMusicServiceInstance();
                musicService.init().then(() => {
                    usePlaylistStore().init();
                    useTrackStore().init();
                    useTagStore().init();
                    useTrackTagStore().init();
                    if (router.currentRoute.value.name === 'home') {
                        router.push({ name: 'dashboard' });
                    }
                });
            }
        } else if (event === 'SIGNED_OUT') {
            Sentry.setUser(null);
            router.push({ name: 'home' });
        }
    });
});
</script>

<template>
    <router-view />
</template>

<style scoped></style>
